<template>
    <v-col cols="12"
           :sm="sm"
           :lg="lg">
        <v-sheet color="white"
                 elevation="1"
                 class="pa-3 w-100">
            <h3>{{heading}}</h3>
            <slot></slot>
        </v-sheet>
    </v-col>
</template>
<script>
    export default {
        name: 'diversity-chart',
        props: {
            heading: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: 'sm',
                validator: function (value) {
                    // The value must match one of these strings
                    return ['sm', 'lg', 'full'].indexOf(value) !== -1
                }
            }
        },
        computed: {
            lg() {
                const { size } = this;
                if (size === 'lg') return 6;
                if (size === 'full') return 12;
                // 's'
               return 4;
            },
            sm() {
                const { size } = this;
                if (size === 'lg') return 12;
                if (size === 'full') return 12;
                // 's'
               return 6;
            },
        }
    }
</script>