<template>
    <diversity-chart :heading="DISABILITIES">
        <doughnut-simple :values="[responders - disabilities, disabilities]"
                        :labels="[DID_NOT_SELECT, DISABILITIES]"
                        :colors="[NEUTRAL_GREY, DISABILITIES_COLOR,]"
                        :options="options"></doughnut-simple>
    </diversity-chart>
</template>
<script>
    import diversityChart from './diversity-chart';
    import doughnutSimple from '@/components/charts/doughnut-simple';
    import { markerNames, doughnutWithInnerTextOptions, reportColors, } from './diversity-reports-constants';

    const { NEUTRAL_GREY, DISABILITIES_COLOR } = reportColors;
    const { DID_NOT_SELECT, DISABILITIES } = markerNames;

    export default {
        name: 'diversity-veteran',
        components: {
            diversityChart,
            doughnutSimple,
        },
        props: {
            responders: {
                type: Number,
                required: true,
            },
            disabilities: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                DID_NOT_SELECT,
                DISABILITIES_COLOR,
                DISABILITIES,
                NEUTRAL_GREY,
            };
        },
        computed: {
            options() {
                const percent = Math.percent(this.disabilities, this.responders);
                return doughnutWithInnerTextOptions(percent + '%', DISABILITIES_COLOR);
            }
        },
    }
</script>