<template>
    <diversity-chart :heading="GAVE_RACIAL_MARKERS"
                     size="lg">
        <doughnut-simple :values="values"
                        :labels="['Gave no markers', DID_NOT_SELECT, GAVE_RACIAL_MARKERS]"
                        :colors="[NEUTRAL_GREY,'#1976d259',  RESPONDERS_COLOR]"
                        :options="options"></doughnut-simple>
    </diversity-chart>
</template>
<script>
    import diversityChart from './diversity-chart';
    import doughnutSimple from '@/components/charts/doughnut-simple';
    import { doughnutWithInnerTextOptions, markerNames, reportColors, } from './diversity-reports-constants';

    const { NEUTRAL_GREY, RESPONDERS_COLOR } = reportColors;
    const { GAVE_RACIAL_MARKERS } = markerNames.racial;

    export default {
        name: 'diversity-racial-responders',
        components: {
            diversityChart,
            doughnutSimple,
        },
        props: {
            racialResponders: {
                type: Number,
                required: true,
            },
            responders: {
                type: Number,
                required: true,
            },
            submissions: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                DID_NOT_SELECT: 'Gave non racial markers only',
                GAVE_RACIAL_MARKERS,
                NEUTRAL_GREY,
                RESPONDERS_COLOR,
            };
        },
        computed: {
            options() {
                const percent = Math.percent(this.racialResponders, this.submissions);
                return doughnutWithInnerTextOptions(percent + '%', RESPONDERS_COLOR);
            },
            values() {
                return [
                    this.submissions - this.responders,
                    this.responders - this.racialResponders, 
                    this.racialResponders
                ];
            },
        },
    }
</script>