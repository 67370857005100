<template>
    <diversity-chart heading="Racial Backgrounds"
                     size="lg">
        <doughnut-simple :values="[racialResponders - notCaucasian, notCaucasian]"
                         :labels="[CAUCASIAN_ONLY, GAVE_OTHER_MARKERS]"
                         :colors="[CAUCASIAN_COLOR, RACIAL_DIVERSITY_COLOR]"
                         :options="options"></doughnut-simple>
    </diversity-chart>
</template>
<script>
    import diversityChart from './diversity-chart';
    import doughnutSimple from '@/components/charts/doughnut-simple';
    import { doughnutWithInnerTextOptions, markerNames, reportColors, } from './diversity-reports-constants';

    const { RACIAL_DIVERSITY_COLOR, racial } = reportColors;
    const { CAUCASIAN, CAUCASIAN_ONLY, GAVE_OTHER_MARKERS } = markerNames.racial;

    export default {
        name: 'diversity-racial-responders',
        components: {
            diversityChart,
            doughnutSimple,
        },
        props: {
            racialResponders: {
                type: Number,
                required: true,
            },
            notCaucasian: {
                type: Number,
                required: true,
            }
        },
        data() {
            const color = `#${racial[CAUCASIAN].primary.slice(1)}26`;
            return {
                CAUCASIAN_ONLY,
                GAVE_OTHER_MARKERS,
                CAUCASIAN_COLOR: color,
                RACIAL_DIVERSITY_COLOR,
            };
        },
        computed: {
            options() {
                const percent = Math.percent(this.notCaucasian, this.racialResponders);
                return doughnutWithInnerTextOptions(percent + '%', RACIAL_DIVERSITY_COLOR);
            }
        },
    }
</script>