<template>
    <diversity-chart :heading="RELIGIOUS">
        <doughnut-simple :values="[responders - religious, religious]"
                        :labels="[DID_NOT_SELECT, RELIGIOUS]"
                        :colors="[NEUTRAL_GREY, RELIGIOUS_COLOR,]"
                        :options="options"></doughnut-simple>
    </diversity-chart>
</template>
<script>
    import diversityChart from './diversity-chart';
    import doughnutSimple from '@/components/charts/doughnut-simple';
    import { doughnutWithInnerTextOptions, markerNames, reportColors } from './diversity-reports-constants';

    const {NEUTRAL_GREY, RELIGIOUS_COLOR} = reportColors;
    const {DID_NOT_SELECT, RELIGIOUS} = markerNames;

    export default {
        name: 'diversity-religious',
        components: {
            diversityChart,
            doughnutSimple,
        },
        props: {
            religious: {
                type: Number,
                required: true,
            },
            responders: {
                type: Number,
                required: true,
            },
        },
        data: () => ({
            DID_NOT_SELECT,
            NEUTRAL_GREY, 
            RELIGIOUS,
            RELIGIOUS_COLOR,
        }),
        computed: {
            options() {
                const percent = Math.percent(this.religious, this.responders);
                return doughnutWithInnerTextOptions(percent + '%', RELIGIOUS_COLOR);
            }
        },
    }
</script>