<template>
    <diversity-chart heading="Breakdown"
                     size="full">
        <v-alert text
                 dense
                 border="left"
                 color="teal"
                 class="mt-1 small-text">
        NB: Submitters are included in <em>each</em> of the racial marker groups they identified with, so will be counted more than once if they selected multiple markers
        </v-alert>
        <bar-chart :chartData="chartData"
                   :options="options"
                   class="racial-breakdown"></bar-chart>
    </diversity-chart>
</template>
<script>
    import barChart from '@/components/charts/horizontal-bar-chart';
    import diversityChart from './diversity-chart';
    import { markerNames, reportColors, } from './diversity-reports-constants';

    const { NEUTRAL_GREY, RESPONDERS_COLOR, racial } = reportColors;
    const {
        ALL_BACKGROUNDS,
        ASIAN,
        BLACK,
        BIRACIAL,
        CAUCASIAN,
        LATINX,
        MIDDLE_EASTERN,
        MORE_THAN_ONE,
        NATIVE_AMERICAN,
     } = markerNames.racial;

    const count = () => ({ only: 0, mixed: 0})

    export default {
        name: 'diversity-racial-breakdown',
        components: {
            barChart,
            diversityChart,
        },
        props: {
            groupedAnswers: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                MORE_THAN_ONE,
                NEUTRAL_GREY,
                RESPONDERS_COLOR,
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            stacked: true,
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    },
                }
            };
        },
        computed: {
            breakdown() {
                const counts = {
                    [ASIAN]: count(),
                    [BLACK]: count(),
                    [BIRACIAL]: count(),
                    [CAUCASIAN]: count(),
                    [LATINX]: count(),
                    [MIDDLE_EASTERN]: count(),
                    [NATIVE_AMERICAN]: count(),
                };

                this.groupedAnswers.reduce((counts, marker) => {
                    // First identify which racial markers are present
                    const backgrounds = ALL_BACKGROUNDS.reduce((bgs, bg)=> {
                        if (marker[bg]) bgs.push(bg);
                        return bgs;
                    }, []);

                    const mixed = backgrounds.length > 1;

                    // Then add the racial markers to the appropriate counter
                    counts = backgrounds.reduce((counts, bg) => {
                        if (mixed)
                            counts[bg].mixed += marker.count;
                        else
                            counts[bg].only += marker.count;

                        return counts;
                    }, counts);
                    
                    return counts;
                }, counts);

                return counts;
            },
            chartData() {
               return {
                   labels: ALL_BACKGROUNDS,
                   datasets: [{
                        label: 'Identified a single racial marker',
                        backgroundColor: ALL_BACKGROUNDS.reduce((data, bg) => {
                            data.push(racial[bg].primary);
                            return data;
                        }, []),
                        data: ALL_BACKGROUNDS.reduce((data, bg) => {
                            data.push(this.breakdown[bg].only);
                            return data;
                        }, []),
                    }, {
                        label: 'Identified with other racial markers',
                        backgroundColor: ALL_BACKGROUNDS.reduce((data, bg) => {
                            data.push(racial[bg].secondary);
                            return data;
                        }, []),
                        data: ALL_BACKGROUNDS.reduce((data, bg) => {
                            data.push(this.breakdown[bg].mixed);
                            return data;
                        }, []),
                   }],
               } 
            },
        }
    }
</script>
<style scoped>
    .small-text {
        font-size: 0.85em;
    }
</style>