<template>
    <diversity-chart heading="Breakdown"
                     size="full">
        <grid-view class="ag-theme-alpine mt-2"
                   :columnDefs="columnDefs"
                   :rowData="allRows"
                   domLayout='autoHeight'>
        </grid-view>
    </diversity-chart>
</template>

<script>
    import diversityChart from './diversity-chart';
    import gridView from '@/components/grid-view';
    import { markerNames } from './diversity-reports-constants';

    const {
        DISABILITIES,
        GAVE_MARKERS,
        LGBTQ,
        OTHER,
        RELIGIOUS,
        SOCIOECONOMIC,
        VETERAN,
    } = markerNames;

    export default {
        name: 'diversity-grid',
        components: {
            diversityChart,
            gridView,
        },
        props: {
           markerCounts: {
               type: Object,
               required: true,
           },
           responders: {
               type: Number,
               required: true,
           },
           submissions: {
               type: Number,
               required: true,
           },
        },
        data: () => ({
           columnDefs: [
               { headerName: 'Marker', field: 'name', flex: 2 },
               { headerName: 'Selected Count', field: 'selected', flex: 1 },
               { headerName: 'Selected % of Responders', field: 'selectedPercentage', flex: 1 },
               { headerName: 'Did Not Select Count', field: 'notSelected', flex: 1 },
               { headerName: 'Did Not Select % of Responders', field: 'notSelectedPercentage', flex: 1 },
           ]
        }),
        computed: {
           allRows() {
                const { makeRow, responders, submissions, markerCounts } = this;
                return [
                    {
                        name: GAVE_MARKERS,
                        selected: responders,
                        selectedPercentage: Math.percent(responders, submissions) + '% (of submissions)',
                        notSelected: submissions - responders,
                        notSelectedPercentage: Math.percent(submissions - responders, submissions) + '% (of submissions)',
                    },
                    makeRow(DISABILITIES, markerCounts[DISABILITIES], responders),
                    makeRow(LGBTQ, markerCounts[LGBTQ], responders),
                    makeRow(RELIGIOUS, markerCounts[RELIGIOUS], responders),
                    makeRow(SOCIOECONOMIC, markerCounts[SOCIOECONOMIC], responders),
                    makeRow(VETERAN, markerCounts[VETERAN], responders),
                    makeRow(OTHER, markerCounts[OTHER], responders),
                ];
           },
        },
        methods: {
           makeRow(name, responses, responders) {
                return {
                    name,
                    selected: responses,
                    selectedPercentage: Math.percent(responses, this.responders) + '%',
                    notSelected: responders - responses,
                    notSelectedPercentage: Math.percent(responders - responses, this.responders) + '%',
                };
           },
        }
    }
</script>