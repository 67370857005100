const DID_NOT_SELECT = 'Did not select';

const ASIAN = 'Asian, Native Hawaiian, Pacific Islander';
const BLACK = 'Black, Afro American, Afro Caribbean';
const BIRACIAL = 'Biracial, Multiracial';
const CAUCASIAN = 'Caucasian';
const LATINX = 'Latinx, Mexican';
const MIDDLE_EASTERN = 'Middle Eastern';
const NATIVE_AMERICAN = 'American Indian, First Nations, Native American, Métis, Inuk';

const ALL_BACKGROUNDS = [
    ASIAN,
    BLACK,
    BIRACIAL,
    CAUCASIAN,
    LATINX,
    MIDDLE_EASTERN,
    NATIVE_AMERICAN,
];

export const markerNames = {
    DID_NOT_SELECT,
    DISABILITIES: 'Person with Disabilities',
    GAVE_MARKERS: 'Gave diversity markers',
    LGBTQ: 'LGBTQ+',
    OTHER: 'Other',
    RELIGIOUS: 'Religious Minority',
    SOCIOECONOMIC: 'From low socioeconomic background',
    VETERAN: 'Veteran',
    racial: {
        ALL_BACKGROUNDS,
        ASIAN,
        BLACK,
        BIRACIAL,
        CAUCASIAN,
        CAUCASIAN_ONLY: 'Caucasian only',
        DID_NOT_SELECT,
        GAVE_OTHER_MARKERS: 'Diverse racial and ethnic backgrounds',
        GAVE_RACIAL_MARKERS: 'Gave racial markers',
        LATINX,
        MIDDLE_EASTERN,
        MORE_THAN_ONE: 'With other racial markers',
        NATIVE_AMERICAN,
    }
}

const opts = (primary, secondary) => ({ primary, secondary });

export const reportColors = {
    DISABILITIES_COLOR: '#ff6e54',
    LGBTQ_COLOR: '#dd5182',
    NEUTRAL_GREY: 'rgba(191, 191, 191, 1)',
    RELIGIOUS_COLOR: '#955196',
    RESPONDERS_COLOR: 'rgba(25, 118, 210, 1)',
    SOCIOECONOMIC_COLOR: '#ffa600',
    VETERAN_COLOR: '#444e86',
    RACIAL_DIVERSITY_COLOR: '#660066',
    racial: {
        [ASIAN]: opts('#8d0c3e', '#d3125c'),
        [BLACK]: opts('#b32400', '#e62e00'),
        [BIRACIAL]: opts('#da5801', '#ff6600'),
        [CAUCASIAN]: opts('#006600', '#00b300'),
        [LATINX]: opts('#006666', '#00cccc'),
        [MIDDLE_EASTERN]: opts('#000099', '#1a1aff'),
        [NATIVE_AMERICAN]: opts('#6600cc', '#8c1aff'),
    }
}

export const doughnutSimpleOptions = {
    allTooltipsOnce: true,
    tooltips: {
    },
    legend: {
        reverse: true
    }
}

export const doughnutWithInnerTextOptions = (text, color) => ({
    ...doughnutSimpleOptions,
    elements: {
        center: {
            text,
            color,
            sidePadding: 20,
            minFontSize: 20,
            lineHeight: 25,
            fontWeight: 800
        },
    },
})