<template>
    <doughnut-chart :chartData="chartData"
                    :options="options"></doughnut-chart>
</template>
<script>
import doughnutChart from './doughnut-chart'

export default {
    name: 'doughnut-simple',
    components: {
        doughnutChart
    },
    props: {
        colors: {
            type: Array,
            required: true,
        },
        labels: {
            type: Array,
            required: true,
        },
        options: Object,
        values: {
            type: Array,
            required: true,
        },
    },  
    computed: {
        chartData() {
            return {
                datasets: [{
                    data: this.values,
                    backgroundColor: this.colors,
                }],
                labels: this.labels
            }
        }
    }
}
</script>
