<template>
    <diversity-chart heading="Gave Diversity Markers">
        <doughnut-simple :values="[submissions - responders, responders]"
                        :labels="['No Markers', 'Gave Markers']"
                        :colors="[NEUTRAL_GREY, RESPONDERS_COLOR]"
                        :options="options"></doughnut-simple>
    </diversity-chart>
</template>
<script>
    import diversityChart from './diversity-chart';
    import doughnutSimple from '@/components/charts/doughnut-simple';
    import { reportColors, doughnutWithInnerTextOptions } from './diversity-reports-constants'

    const {NEUTRAL_GREY, RESPONDERS_COLOR} = reportColors;

    export default {
        name: 'diversity-responders',
        components: {
            diversityChart,
            doughnutSimple,
        },
        props: {
            responders: {
                type: Number,
                required: true,
            },
            submissions: {
                type: Number,
                required: true,
            }
        },
        data: () => ({
            NEUTRAL_GREY, 
            RESPONDERS_COLOR
        }),
        computed: {
            options() {
                const percent = Math.percent(this.responders, this.submissions);
                return doughnutWithInnerTextOptions(percent + '%', RESPONDERS_COLOR);
            }
        },
    }
</script>