<template>
    <app-navigation heading="Diversity Reporting"
                    class="mb-4">
        <template #toolbar>
            <v-spacer></v-spacer>
            <v-col cols="12"
                   sm="6"
                   md="1">
                <date-picker v-model="startDate"
                             label="Start Date"></date-picker>
            </v-col>
            <v-col cols="12"
                   sm="6"
                   md="1">
                <date-picker v-model="endDate"
                             label="End Date"></date-picker>
            </v-col>
            <v-col cols="6"
                    sm="4"
                    md="2">
                <v-select v-model="selectedProjects"
                            :items="projectArray"
                            label="Projects"
                            placeholder="No selection: results for all projects"
                            item-text="name"
                            item-value="_id"
                            multiple
                            hide-details
                            persistent-hint>
                </v-select>
            </v-col>
            <div>
                <v-btn @click="fetchData" 
                       :loading="loading"
                       text>Run</v-btn>
            </div>
        </template>
        <v-container>
            <v-row v-if="hasRun && !loading">
                <responders-chart :responders="totalResponders"
                                :submissions="totalSubmissions"></responders-chart>
                <lgtbq-chart :responders="totalResponders"
                             :lgbtq="lgbtq"></lgtbq-chart>
                <socioeconomic-chart :responders="totalResponders"
                                     :socioeconomic="socioeconomic"></socioeconomic-chart>
                <disabilities-chart :responders="totalResponders"
                                    :disabilities="disabilities"></disabilities-chart>
                <religious-chart :responders="totalResponders"
                                 :religious="religious"></religious-chart>
                <veteran-chart :responders="totalResponders"
                               :veterans="veterans"></veteran-chart>
                <diversity-grid :marker-counts="diversityData.individualCounts"
                                :responders="totalResponders"
                                :submissions="totalSubmissions"></diversity-grid>
                <v-col cols="12">
                    <h1>Racial Backgrounds</h1>
                </v-col>
                <racial-responders-chart :responders="totalResponders"
                                         :racial-responders="racialCount.gaveRacialMarker"
                                         :submissions="totalSubmissions"></racial-responders-chart>
                <racial-diversity-chart :racial-responders="racialCount.gaveRacialMarker"
                                        :not-caucasian="racialCount.notCaucasianOnly"></racial-diversity-chart>
                <racial-breakdown-chart :grouped-answers="diversityData.groupedAnswers"></racial-breakdown-chart>
            </v-row>
            <v-row v-else-if="loading">
                    <v-sheet color="white"
                             elevation="1"
                             class="pa-3">
                        <h3>Running Diversity Reports</h3>
                        <p>Collecting the data, please hold tight</p>
                    </v-sheet>
            </v-row>
            <v-row v-else>
                    <v-sheet color="white"
                             elevation="1"
                             class="pa-3">
                        <h3>Run Diversity Reports</h3>
                        <p>Select what you want to report on in the top right corner and then hit run</p>
                    </v-sheet>
            </v-row>
        </v-container>
    </app-navigation>
</template>

<script>
    import { mapGetters } from 'vuex';
    import dayjs from 'dayjs';

    import appNavigation from '@/components/app-navigation';
    import datePicker from '@/components/date-picker'

    // Charts
    import disabilitiesChart from '../components/reports/diversity-disabilities';
    import diversityGrid from '../components/reports/diversity-grid';
    import lgtbqChart from '../components/reports/diversity-lgtbq+';
    import racialBreakdownChart from '../components/reports/diversity-racial-breakdown';
    import racialDiversityChart from '../components/reports/diversity-racial-diversity';
    import racialRespondersChart from '../components/reports/diversity-racial-responders';
    import religiousChart from '../components/reports/diversity-religious';
    import respondersChart from '../components/reports/diversity-responders';
    import socioeconomicChart from '../components/reports/diversity-socioeconomic';
    import veteranChart from '../components/reports/diversity-veteran';

    import {markerNames} from '../components/reports/diversity-reports-constants';

    export default {
        name: 'diversity-reports',
        components: {
            appNavigation,
            datePicker,
            diversityGrid,
            disabilitiesChart,
            lgtbqChart,
            racialBreakdownChart,
            racialDiversityChart,
            racialRespondersChart,
            religiousChart,
            respondersChart,
            socioeconomicChart,
            veteranChart,
        },
        data() {
            return {
                diversityData: null,
                endDate: dayjs().format('YYYY-MM-DD'),
                hasRun: false,
                loading: false,
                selectedProjects: [],
                startDate: dayjs().add(-6, 'months').format('YYYY-MM-DD'),
            };
        },
        computed: {
            disabilities() {
                return this.diversityData.individualCounts[markerNames.DISABILITIES];
            },
            lgbtq() {
                return this.diversityData.individualCounts[markerNames.LGBTQ];
            },
            racialCount() {
                return this.diversityData.racialCount;
            },
            religious() {
                return this.diversityData.individualCounts[markerNames.RELIGIOUS];
            },
            socioeconomic() {
                return this.diversityData.individualCounts[markerNames.SOCIOECONOMIC];
            },
            totalResponders() {
                return this.diversityData.totalResponders;
            },
            totalSubmissions() {
                return this.diversityData.totalSubmissions;
            },
            veterans() {
                return this.diversityData.individualCounts[markerNames.VETERAN];
            },
            projectArray() {
                return Object.values(this.projects);
            },
            projectFilterQuery() {
                return this.selectedProjects
                    .reduce((query, projectId) => query += `&projectIds=${projectId}`, '');
            },
            ...mapGetters('organisation/projects', ['projects']),
            ...mapGetters('organisation/admin', ['prependUrl',]),
        },
        methods: {
            fetchData() {
                this.hasRun = true;
                this.loading = true;
                const { startDate, endDate, projectFilterQuery } = this;

                // To account for creation time being in the day, the filter should be until the day 
                // after they chose
                const adjustedEndDate = dayjs(endDate).add(1, 'days').format('YYYY-MM-DD')
                
                const url = `/reports/diversity?startDate=${startDate}&endDate=${adjustedEndDate}${projectFilterQuery}`

                this.$http.get(this.prependUrl(url))
                    .then(({ data: diversityData }) => {
                        this.diversityData = diversityData;
                        this.loading = false;
                    });
            },
        },
    };
</script>