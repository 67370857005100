<template>
    <v-menu v-model="show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="valueFormatted"
                          :label="label"
                          prepend-icon="mdi-calendar"
                          readonly
                          hide-details
                          v-bind="attrs"
                          v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="valueBound"
                       @input="show = false">
        </v-date-picker>
    </v-menu>
</template>

<script>
    import dayjs from 'dayjs';

    export default {
        name: 'date-picker',
        props: {
            label: {
                type: String,
                required: true,
            },
            value: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            show: false
        }),
        computed: {
            valueBound: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit('input', newValue);
                },
            },
            valueFormatted() {
                return dayjs(this.valueBound)
                    .format('DD/MM/YYYY');
            } 
        }
    }
</script>