<template>
    <diversity-chart :heading="VETERAN">
        <doughnut-simple :values="[responders - veterans, veterans]"
                        :labels="[DID_NOT_SELECT, VETERAN]"
                        :colors="[NEUTRAL_GREY, VETERAN_COLOR]"
                        :options="options"></doughnut-simple>
    </diversity-chart>
</template>
<script>
    import diversityChart from './diversity-chart';
    import doughnutSimple from '@/components/charts/doughnut-simple';
    import { doughnutWithInnerTextOptions, markerNames, reportColors, } from './diversity-reports-constants';

    const {NEUTRAL_GREY, VETERAN_COLOR} = reportColors;
    const {DID_NOT_SELECT, VETERAN} = markerNames;

    export default {
        name: 'diversity-veteran',
        components: {
            diversityChart,
            doughnutSimple,
        },
        props: {
            responders: {
                type: Number,
                required: true,
            },
            veterans: {
                type: Number,
                required: true,
            }
        },
        data: () => ({
            DID_NOT_SELECT,
            NEUTRAL_GREY, 
            VETERAN,
            VETERAN_COLOR,
        }),
        computed: {
            options() {
                const percent = Math.percent(this.veterans, this.responders);
                return doughnutWithInnerTextOptions(percent + '%', VETERAN_COLOR);
            }
        },
    }
</script>