<template>
    <diversity-chart :heading="SOCIOECONOMIC">
        <doughnut-simple :values="[responders - socioeconomic, socioeconomic]"
                        :labels="[DID_NOT_SELECT, SOCIOECONOMIC]"
                        :colors="[NEUTRAL_GREY, SOCIOECONOMIC_COLOR,]"
                        :options="options"></doughnut-simple>
    </diversity-chart>
</template>
<script>
    import diversityChart from './diversity-chart';
    import doughnutSimple from '@/components/charts/doughnut-simple';
    import { doughnutWithInnerTextOptions, markerNames,reportColors, } from './diversity-reports-constants';

    const {NEUTRAL_GREY, SOCIOECONOMIC_COLOR} = reportColors;
    const {DID_NOT_SELECT,SOCIOECONOMIC} = markerNames;

    export default {
        name: 'diversity-socioeconomic',
        components: {
            diversityChart,
            doughnutSimple,
        },
        props: {
            responders: {
                type: Number,
                required: true,
            },
            socioeconomic: {
                type: Number,
                required: true,
            }
        },
        data: () => ({
            DID_NOT_SELECT,
            NEUTRAL_GREY, 
            SOCIOECONOMIC,
            SOCIOECONOMIC_COLOR,
        }),
        computed: {
            options() {
                const percent = Math.percent(this.socioeconomic, this.responders);
                return doughnutWithInnerTextOptions(percent + '%', SOCIOECONOMIC_COLOR);
            }
        },
    }
</script>