<script>
    import { AgGridVue } from "ag-grid-vue";

    export default {
        name: 'grid-view',
        extends: AgGridVue,    
    }
</script>

<style lang="scss">
  @import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
  @import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>